@import '_includes.scss';
html{
    scroll-behavior: smooth;
}
ul{
    padding-left:0;
}

.external-link {
    &:after {
        content: "";
        display:inline-block;
        margin:0 0 0 5px;
        background:url('/images/icons/external-link.svg') no-repeat center;
        background-size: contain;
        width:25px;
        height: 25px;
    }
}
:target:before {
    content: "";
    display: block;
    height: 100px;
    margin: -100px 0 0;
}