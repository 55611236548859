figure.fullwidth-embed { 
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0; 
    overflow: hidden;
    margin-top:50px;
}

figure.fullwidth-embed iframe,
figure.fullwidth-embed object,
figure.fullwidth-embed embed { 
    position: absolute;
    top: 0; 
    left: 0; 
    width: 100% !important; 
    height: 100% !important; 
 }