.green-split {
	@include fullwidthLimit;
	margin-top: 6.25rem;
	margin-bottom: 6.25rem;
	overflow: hidden;
	.row {
		align-items: center;
	}
	.sub-hero--left {
		position: relative;
		margin-bottom: 2rem;
		@include gothic;
		color: var(--white);
		padding: 87px 99px 87px 0;
		font-size: 31px;
		line-height: 38px;
		&::before {
			position: absolute;
			top: 0px;
			right: 0px;
			height: 100%;
			width: 100vw;
			border-top-right-radius: 20px;
			border-bottom-right-radius: 20px;
			background-color: var(--green);
			content: '';
		}
	}
	p {
		position: relative;
		margin: 0px;
	}
	.sub-hero--right {
		position: relative;
		color: var(--body-text);
	}
}
