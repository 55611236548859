.full-width-text-block{
    ul{
        margin-left:20px;        
    }
    table{
        width: 100%;
        max-width: 100%;
        margin-bottom: 1rem;
        background-color: transparent;

        td, th{
            padding: .75rem;
            vertical-align: top;
            border-top: 1px solid #dee2e6;
            border: 1px solid #dee2e6;

        }
        th, thead th {
                vertical-align: bottom;
                border-bottom: 2px solid #dee2e6;
        }
    }
}