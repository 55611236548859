header{
    .top-bar{
        background-color:var(--green);
        color:var(--white);
        padding-top:5px;
        padding-bottom:5px;
        font-size:$small;
        position: relative;
        border-bottom:5px transparent solid;
        &:after{
            background:linear-gradient(90deg, var(--primary),var(--white), var(--primary));
            width:100%;
            height:5px;
            content:'';
            bottom:-5px;
            left:0;
            position: absolute;
        }
        .right-menu{
            display: flex;
            align-items: center;
            justify-content: flex-end;
         li{
                margin-left:15px;
            }
        }
        ul{
            @include gothic;
            list-style:none;
            display:flex; 
            margin-bottom:0;
            
            &.challenge-create-transform{
                li + li{
                    &:before{
                        content:'\2022';
                        padding:5px;
                        color:var(--primary);
                    }
                }
            }
            a{
                color:var(--white);
            }
        }

        .header-top-nav {
            flex-grow:2;
        }

        .btn-primary{
            background-color:var(--white);
            color:var(--green);
            border-color:var(--white);
        }
        .account-icon{
            &, svg {
                width: 25px;
                height: 25px;
            }
        }
    }
    .header-bar{
        padding-top:10px;
        padding-bottom:10px;
        position: relative;
        &:after {
            width:100%;
            height: 4px;
            position: absolute;
            transform: translateY(100%);
            left:0;
            bottom:0;
            content: '';
            background:var(--primary);
            opacity: 0;
            transition: 0.3s ease-in-out;
        }
        .menu-item-image{
         max-width:290px;       
        }
        .menu-title{
            white-space: nowrap;
        }
        .account-icon{
            &, svg {
                width: 25px;
                height: 25px;
                .st0 {
                    fill: var(--body-text);
                }
            }
        }
        .logo{
            img{
                width: 180px;
                //height: 83px;
                @include media-breakpoint-up(lg) {
                    width:244px;
                }
            }
        }
    }

    .hamburger {
        padding:15px 0 10px 0;
    }
}

.fixed-nav .header-bar {
    @include media-breakpoint-up(md) {
        position: fixed;
        top:0;
        z-index: 1;
        width:100%;
        background:var(--white);
        z-index: 1025;
    }
    &:after {
        opacity: 0.1;
    }
  }