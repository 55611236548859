footer{
    position: relative;
    border-top:30px var(--green) solid;
    padding:40px 0;
    background-color: var(--background-grey);
    margin-top:40px;
    @include media-breakpoint-up(md){
        max-width: 1600px;
        margin:0 auto;
        margin-top:40px;
    }
    &:before{
        background:linear-gradient(90deg, var(--primary),var(--white), var(--primary));
        width:100%;
        height:15px;
        content:'';
        top:-30px;
        left:0;
        position: absolute;
    }
    .logo img{
        width: 246px;
        height: 89px;
    }
    .form-row{
        flex-direction: row-reverse;
    }
    ul{
        @include gothic;
        list-style:none;
        margin-bottom:30px;
        @include media-breakpoint-up(lg) {
            margin-bottom:0;
        }
        
        &.challenge-create-transform{
            li + li{
                margin:0;
                &:before{
                    content:'\2022';
                    padding:5px;
                    color:var(--primary);
                }
            }
        }
        a{
            color:var(--white);
        }
    }
    .footer-links{
        ul{
            list-style: none;
            display: flex;
            justify-content: center;
            @include arial;
            a{
                color: var(--body-text);
            }
            li{
                &:after {
                    content:'|';
                    padding: 0 5px;
                    @include media-breakpoint-up(sm){
                        padding:0 10px;
                    }
                }
                &:last-of-type {
                    &:after {
                        display: none;
                    }
                }
            }

        }
    }
    .arrow-accessory{
        width:20px;
        height:20px;
    }

    .contact-details{
        padding-top:40px;
        text-align: center;
        a{
            border-bottom: 2px var(--primary-transparent) solid;
            width:100%;
            display:block;
            @include gothic;
            &:hover {
                text-decoration: none;
                border-bottom-color:var(--primary);
            }
        }
    }
    .address-details{
        text-align: center;
        address{
            @include media-breakpoint-up(md) {
                display: inline;
            }
        }

        address {
            &:after {
                @include media-breakpoint-up(md) {
                    content:'|';
                    padding:0 10px;
                }
            }
            &:last-child{
                &:after{
                    @include media-breakpoint-up(md) {
                        display:none
                    }
                }
            }
        }
        span {
            &:after {
                content:'|';
                padding:0 10px;
            }
        }
        
        span:last-of-type {
            &:after {
                display: none;
            }
        }
    }
    .social-icons{
        padding:40px 0;
        text-align: center;
        
    }
    .social-icon{
        width:40px;
        height:40px;
    }
    .input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text{
        background-color:var(--primary);
        border:var(--primary);
        border-top-right-radius:8px;
        border-bottom-right-radius:8px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        height: calc(100% - 1px);
    }
    .input-group{
        .input-group-append{
            button.input-group-text {
                background-color:var(--primary);
                border:var(--primary);
                border-top-right-radius:8px;
                border-bottom-right-radius:8px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }
}

.form-control{
    border-color:var(--border-color);
    font-size:$small;
    &::placeholder{
        color:var(--grey-text);
    }
}
