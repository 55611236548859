.agenda {
   padding:80px 0;
   margin:40px 0;

   h1 {
      margin-bottom:15px;
   }

   .agenda-list {
      padding-left:20px;
      margin-top:15px;
   }
}