.webinar-banner {
   background-color:var(--primary);
   color:#fff;
   position: relative;
   overflow: hidden;

   .webinar-banner-content {
      position: relative;
      top:50%;
      transform:translateY(-50%);
      .lead {
         margin:20px 0 0 0;
      }
   }
   
   h1,h2 {
      color:#fff;
      margin-bottom:15px;
   }
   .background-img {
      filter: blur(5px);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      position: absolute;
      top:0;
      right:0;
      bottom:0;
      left: 0;
      opacity: 0.1;
   }

   .banner-form-wrap {
      background:rgba(#004E86,0.5);
      padding:40px;
      h3 {
         color:#fff;
      }
      .btn {
         position: relative;
         left:50%;
         transform:translateX(-50%);
         margin-top:25px;
      }
      .form-group > ul.errors{
         color:#fff;
         border:2px solid var(--red);
         padding:5px 10px;
         border-radius: $border-radius-xs;
         list-style: none;
       }
   }
}

.webinar-date-banner {
   background:var(--green);
   padding:20px 0;
   h2,h3 {
      color:#fff;
   }
   .share-links {
      margin-top:10px;
      @include media-breakpoint-up(lg) {
         margin-top:0;
      }
      .share-icon {
         margin-right:10px;
      }
   }
   .live-now {
      border:2px solid #fff;
      width:100%;
      padding:20px;
      text-align: center;
      border-radius: $border-radius-sm;
      h2 {
         margin:0;
      }
   }
}

.countdown-timer {
   .time {
      span {
         display: block;
         color:#fff;
      }
      &.h2 {
         color:#fff;
      }
   }
}