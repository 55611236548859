.elc-primary-heading {
  @include gothic;
  font-size: $h2;
}
html {
  #elc-cookie-consent {
    max-width: calc(100% - 3rem);
    .elc-text-link {
      
    }
    .elc-button-link,
    .elc-save-link{
        background-color: #0067b1;
        border-radius: 5px;
        border: 0;
        font-family: 'arial', sans-serif;
        font-size: 0.9rem;
        font-weight: bold;
        height:auto;
        line-height: 1.5;
        padding: 5px 10px;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;        

    }
  }
  .elc-small {
    width: 40rem;
  }
}
