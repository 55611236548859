header {
  .header-bar {
    nav {
      ul {
        @include gothic;
        list-style: none;
        display: flex;
        margin-bottom: 0;
        @include media-breakpoint-down(md) {
          width:100%;
          ul li{
            margin-left: 30px;
          }
          .btn.btn-primary{
            width:100%;
          }
        }
        li {
          @include media-breakpoint-up(md) {
            margin-left: 20px;
            &:first-child {
              margin-left: 0;
            }
          }
        }
        a {
          color: var(--dark-text);
          &.btn {
            color: var(--white);
          }
        }
      }
    }
  }
  .menu-content {
    z-index: 1000;
    position: absolute;
    bottom: 0;
    left:0;
    width:100%;
    transform:translatey(100%);
    .menu-content-container{
        position: relative;
    }
    .menu-block{
        position: absolute;
        top:0;
        left:0;
        width:100%;
        opacity: 0;
        min-height:0;
        background-color:var(--white);
        transition: 0.25s ease-in-out opacity, 0s 0.25s ease-in-out min-height, 0s 0.25s ease-in-out padding;
        overflow: hidden;
        pointer-events: none;
    }
    .menu-block.active{
        padding:32px 0;
        opacity: 1;
        min-height:200;
        transition: 0.25s ease-in-out opacity, 0s ease-in-out min-height, 0s ease-in-out padding;
        pointer-events: all;
    }
    .brand-block {
      h2 {
        margin-bottom: 20px;
      }
      a {
        &:after {
          $size: 7px;
          content: ' ';
          display: inline-block;
          width: $size;
          height: $size;
          border: 2px var(--primary) solid;
          border-bottom: 0px;
          border-left: 0px;

          transform: translate(5px, -2px) rotate(45deg);
        }
      }
    }
    .menu-sub-items {
      list-style: none;
      columns: 2;
      position: relative;
      min-height: 100px;
      &:before {
        left: 0;
      }
      &:after {
        left: 50%;
      }
      &:before,
      &:after {
        border-left: 1px var(--border-color) solid;
        position: absolute;
        top: 0;
        transform: translatex(-15px);
        height: 100%;
        content: '';
      }
      li {
        margin-bottom: 15px;
        a {
          color: var(--body-text);
        }
      }
    }
  }
}

.mobile-nav {
  [data-toggle="collapse"] {
    position: relative;
    &:after {
      top:50%;
      right:0;
      width:15px;
      height: 15px;
      position: absolute;
      content: "";
      background:url('/images/icons/menu-arrow.svg') no-repeat center;
      background-size:contain;
      transform:rotate(180deg);
      transition:0.25s ease-in-out;
    }
    &.collapsed:after {
      transform:rotate(0deg);
    }
  }
  a {
    display: block;
  }
  ul {
    padding: 20px 0;
    ul {
      padding: 10px 0;
    }
  }
  li {
    padding: 5px 0;
  }
}