.breadcrumbs{
  ul{
    padding:20px 0;
    list-style:none;
    display:flex;
    font-size:$small;
    margin-bottom:0;
    li + li{
      &:before{
        content: '/';
        padding:0 5px;
      }
    }
    a{
      color:var(--body-text);
      text-decoration: underline;
      &:hover {
        color:var(--primary);
      }
    }
    li{
      
      &:last-child{
        color: var(--body-text);
        opacity: 0.6;
      }
    }
  }
}