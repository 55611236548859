.full-width-image {
   padding:40px 0;
   
   figure {
      &.has-testimonial {
         @include media-breakpoint-up(md) {
            border-radius: $border-radius-lg;
            overflow: hidden;
            position: relative;
         }
         &:before {
            @include media-breakpoint-up(md) {
               position: absolute;
               top:0;
               right:0;
               bottom:0;
               left:0;
               background: rgb(0,0,0);
               background: linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.6) 100%);
               content:"";
               z-index: 1;
            }
         }
         .figure-img {
            margin-bottom:15px;
            @include media-breakpoint-up(md) {
               margin:0;
            }
         }
      }
   }
   .testimonial {
      @include media-breakpoint-up(md) {
         position: absolute;
         z-index: 2;
         padding:20px;
         bottom:0;
         left:0;
         padding:40px;
      }

      h1 {
         font-size:$h3;
         margin-bottom:15px;
         @include media-breakpoint-up(md) {
            color:#fff;
         }
         @include media-breakpoint-up(lg) {
            color:#fff;
            font-size:$h1;
         }
      }
      h2 {
         @include media-breakpoint-up(md) {
            color:#fff 
         }
      }
   }
}