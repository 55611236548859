.fixed-btn {
   position: fixed;
   right:0;
   bottom:0;
   width:100%;
   z-index:2;
   opacity: 0;
   transition: all 1s ease-in-out;
   pointer-events: none;
   text-align: center;
   padding:20px;
   &.active {
      background:rgba($color: #fff, $alpha: 0.6);
      display: block;
      opacity:1;
      pointer-events: auto;
   }
}