.pipeline-section {
    margin: 40px 0;
    max-width: 100%;
    overflow-x: auto;
    &.active .pipeline-rows .pipeline-row .pipeline-bar {
        transition: width 2.5s ease-in-out;
        @for $i from 1 through 20 {
            &.pipeline-bar-end-#{$i} {
                width: calc(100% * #{$i} - 20px);
            }  
            &.pipeline-bar-start-#{$i} {
                width: calc(100% * #{$i} - 100%  + 20px);
            }  
            &.pipeline-bar-mid-#{$i} {
                width: calc(100% * #{$i} - 50%);
            }  
        } 
    }
    .scroll-container {
        min-width: 700px;
    }
    .pipeline-title {
        background-color: var(--primary);
        color: var(--white);
        padding: 10px;
    }
    .pipeline-headings {
        display: flex;
        justify-content: space-around;
        text-align: center;

        .pipeline-heading {
            border: 1px white solid;
            background-color: var(--green);
            color: var(--white);
            flex: 1;
            padding: 5px 10px;
            &:first-child {
                flex: 2;
                border-left: 0px;
            }
            &:last-child {
                border-right: 0px;
            }
            span {
                transform: rotate(90deg);
            }
        }
    }
    .pipeline-rows {
        $height: 30px;
        .pipeline-bar {
            position: absolute;
            background-color: var(--primary);
            height: $height;
            width: 0;
            top: 50%;
            left: 0;
            transform: translatey(-50%);
            z-index: 1;
            &:after {
                content: "";
                border: $height / 2 var(--primary) solid;
                right: -$height;
                top: 0;
                height: 0;
                width: 0;
                position: absolute;
                border-right-color: transparent;
                border-left-color: var(--primary);
                border-top-color: transparent;
                border-bottom-color: transparent;
                transition: width 2.5s ease-in-out;
            }
            
        }
        .pipeline-row {
            display: flex;
            justify-content: space-around;
            align-content: stretch;
            min-height: 60px;
            border-bottom: #ccc 1px solid;
            &:last-child {
                border-bottom: 0px;
            }
            > div {
                flex: 1;
                position: relative;
                padding: 5px 10px;
                display: flex;
                min-height: 60px;
                justify-content: center;
                align-self: stretch;
                align-items: center;
                border-right: #ccc 1px solid;
                &:last-child {
                    border-right: 0px;
                }
                &:first-child {
                    flex: 2;
                    justify-content:flex-start;
                }
            }
        }
    }
}
