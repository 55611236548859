.news-events {
   margin-bottom:60px;
   .news-event-item {
      margin-bottom:40px;
      .event-date span {
         display: block;
      }
   }

   .categories {
      margin-bottom:30px;
   }
   
   .entry-type {
      text-transform: capitalize;
   }

   

   .news-icon {
      background-color: var(--background-grey);
      padding:10px;
      text-align: center; 
      padding:30px 10px;
      img {
         max-width:40px;
      }
   }
}

.event-date {
   border-radius: $border-radius-xs;
   background-color: var(--background-grey);
   padding:10px;
   text-align: center;
   line-height: 1.2;
   @include gothic;
   .month {
      font-size:$h3;
   }
   &.upcoming-event {
      border:1px solid var(--green);
      position: relative;
      padding-top:15px;
      color:var(--green);
      &:before {
         position:absolute;
         top:0;
         left:50%;
         transform: translate(-50%,-50%);
         background-color: var(--green);
         padding:2px 5px;
         color:#fff;
         content: "Upcoming";
         font-size:$h6;
         border-radius: $border-radius-sm;
      }
   }
}

.news-article {
   padding:15px 0;
   border-top:1px solid var(--border-color);
   @include media-breakpoint-up(lg) {
      padding:40px 0;
   }
   .event-date {
      margin-bottom:40px;
   }
}

