.rpt-pipeline {
    .pipeline-section {
        .pipeline-headings {
            .pipeline-heading {
                &:first-child {
                    flex: 1;
                }
                &:nth-child(2) {
                flex: 2;
                }
            }
        }
        .pipeline-rows {
            .pipeline-row { 
                > div {  
                    text-align: center;
                    &:first-child {
                        flex: 1;
                        justify-content: center;
                    }
                    &:nth-child(2) {
                        flex: 2;
                        justify-content: center;
                    }
                }
            }
        }
    }
}