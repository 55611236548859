.horizontal-divider {
   padding:60px 0;
   @include media-breakpoint-up(lg) {
      padding:80px 0;
   }
   hr {
      margin:0;
      border-top:2px solid var(--border-color);
      position: relative;
      &.show-icon:after {
         position: absolute;
         left:50%;
         top:50%;
         transform:translate(-50%,-50%);
         width:60px;
         height: 60px;
         content:"";
         z-index: 2;
         background:url('/images/logo-icon.svg') no-repeat var(--white) center;
         background-size:50%;
         @include media-breakpoint-up(lg) {
            width:80px;
            height: 80px;
         }
      }
   }
}