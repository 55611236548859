.technology {
  padding-bottom:40px;
  .section-heading {
    text-align: center;
    @include gothic;
    color: var(--primary);
    font-size: 31px;
    line-height: 38px;
    margin-bottom: 60px;
  }

  .technology-item {
    text-align: center;
    margin-bottom: 26px;
  }

  .technology-item .embed-responsive {
    margin-bottom: 1.25rem;
    position: relative;
    .logo-item-container{
      position: absolute;
      bottom:30px;
      left:50%;
      background-color:#ffffffdd;
      padding:30px;
      border-radius: $border-radius-lg;
      max-width: calc(100% - 60px -20px);
      transform:translatex(-50%);
      img{
        max-width:100%;
        width:290px;
      }
    }
  }

  .technology-item .embed-responsive-item {
    border-radius: $border-radius-lg;
    object-fit: cover;
  }

  .technology-item-heading {
    margin-bottom: 1rem;
  }

  .technology-item-subheading {
  }
}
