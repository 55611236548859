.contact{
  form{
    margin-top:20px;
    margin-bottom:20px;
  }
  h2{
    margin-top:20px;
  }
  ul.contact-info{
    font-size:1.2rem;
    @include gothic;
    list-style:none;
    li{
      display:flex;
      margin:20px 0;
      align-content: center;
      span{
        width:50px;
        display: flex;
        margin-right:20px;
        justify-content: center;
        justify-self: center;
        height: 50px;
        img{
          object-fit: contain;
          max-width: 100%;
        }
      }
      a{
        align-self: center;
      }
    }
    .icon{
      display:inline-block;
      margin-right:10px;
      width: 40px;
      height:40px;
      border:1px var(--green) solid;
    }
  }
} 