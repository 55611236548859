.btn{
    border-radius:$border-radius-sm;
    font-weight: bold;
    font-family:'arial',sans-serif;
    padding:10px 20px;
    border:0;

    &.btn-light {
        color:var(--primary);
    }

    &.btn-sm {
        font-size:$small;
        border-radius:5px;
        padding:5px 10px;
    }

    &.btn-secondary {
        color:var(--primary);
    }

    &.btn-back {
        &:before {
            width:20px;
            height: 20px;
            margin-right:5px;
            content:"\2190";
        }
    }

    &.btn-list {
        @extend .btn-light;
        font-weight:normal;
        color:$body-color;
        box-shadow: 10px 10px 30px rgba($secondary,0.1);
        text-align: left;
        border-radius: 3px;
    }
}