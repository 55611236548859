.fullwidth-cta {
  padding: 40px 0;
  position: relative;

  .wavy-line {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    pointer-events: none;

    svg {
      object-fit: contain;
      height: 100%;
    }
  }
}

.fullwidth-cta-bg {
  border-radius: $border-radius-lg;
  padding: 15px;
  position: relative;
  @include media-breakpoint-up(md) {
    padding: 30px;
  }

  h2 {
    color: var(--white);
    margin-bottom: 15px;
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }

  .btn-primary {
    background-color: var(--white);
    border-color: var(--white);
    color: var(--primary);
    display: block;
  }
}
