.sub-page-links {
   padding:80px 0;
   margin:40px 0;
   .sub-page-icon {
      margin-bottom:15px;
      max-width:100px;
   }
   .banner-title {
      text-align: center;
      margin-bottom:40px;
   }
}