.register-for-event {

   .register-form-container {
      background: var(--background-grey);
      border-radius: $border-radius-lg;
      padding:20px;
      @include media-breakpoint-up(lg) {
         padding:60px;
      }

      .register-form {
         margin-top:40px;
         max-width:300px;
         position:relative;
         left:50%;
         transform:translateX(-50%);
         .btn {
            position:relative;
            left:50%;
            transform:translateX(-50%);
         }
      }
   }
}