.icon-blocks {
   padding:40px 0;
   text-align: center;
   h2 {
      margin-bottom:5px;
   }
   .intro {
      margin-bottom:20px;
   }
   .icon-block {
      margin-bottom:30px;
   }
   .icon-block-icon {
      margin-bottom:15px;
      max-width:100px;
   }
}