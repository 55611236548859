.in-page-nav-wrapper {
   .in-page-nav {
      
      background:var(--background-grey);
      @include media-breakpoint-up(md) {
         position: sticky;
         top: 0;
         z-index: 1020;
      }
      .nav-item {
         margin:0 15px;
      }
      .nav-link {
         padding:15px 0;
         transition:0.3s ease-in;
         border-bottom:2px solid var(--background-grey);
         &.active {
            border-bottom:2px solid var(--primary);
         }
      }
   }
   .page-section {
      position: relative;
   //    &:target:before {
   //       @include media-breakpoint-up(md) {
   //          content: "";
   //          display: block;
   //          height: 100px;
   //          margin: -100px 0 0;
   //          border:1px solid red;
   //       }
   //   }
   }
}