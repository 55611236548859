.categories{
   ul {
     list-style: none;
     width:100%;

     li {
       border-bottom: 1px var(--border-color) solid;
       &:last-child{
         border-bottom: 0;
       }
       a {
         padding:10px 5px;
         width:100%;
         display:inline-block;
         color:var(--body-text);
       }

       &.active {
         a {
           font-weight:bold;
           color: var(--primary);
         }
       }
     }
   }
   .categories-toggler {
     background:var(--background-grey);
     padding:10px;
     font-weight: normal;
     margin-bottom:15px;
   }
   .sub-categories{
     border-top:1px var(--border-color) solid;
     &.hide{
       visibility: hidden;
       height:0;
       overflow: hidden;
     }
     li a{
       padding-left:20px;
     }
   }
 }