html body #hiringthing-jobs {
    margin-top:20px;
    .ht-apply-link {
        border-radius: 8px;
        font-weight: bold;
        font-family: "arial", sans-serif;
        padding: 10px 20px;
        border: 0;
        background-color: #0067b1;
        color: var(--white);
        display: inline-block;
        text-align: center;
        vertical-align: middle;
        margin-top:10px;
        &:hover {
            text-decoration:none;
        }
    }
    .ht-title-link {
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -webkit-text-stroke-width: 0.2px;
        -moz-osx-font-smoothing: grayscale;
        line-height: 1.25;
        color: var(--primary);
        margin: 0;
        font-family: all-round-gothic, sans-serif;
    }
}