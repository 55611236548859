.form-background {
  background-color: var(--background-grey);
  border-radius: 20px;
  padding: 40px;
}

.form-background-sm {
  background-color: var(--background-grey);
  border-radius: 20px;
  padding: 30px;
}
