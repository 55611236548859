.modal-backdrop {
   background-color: var(--body-text);
   &.show {
      opacity: 0.8;
   }
}

.modal-header {
   padding:0;
   display: block;
   border:0;
   button {
      float: right;
      transform: scale(0.8);
   }
}

.modal-body {
   padding:0 15px 15px 15px;
   @include media-breakpoint-up(lg) {
      padding:0 30px 30px 30px;
   }
}

.modal-dialog {
   margin:10px auto;
   width:calc(100% - 20px);
   max-width:700px;
}