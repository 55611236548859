.tabbed-content {
   padding:80px 0;
   background-color:var(--background-grey);
   margin:40px 0;
   .nav-tabs {
      border:0;
   }
   .nav-item {
      border-radius:5px 5px 0 0;
      &.active {
         background-color:var(--white);
      }
   }
   .nav-link {
      border:0;
      padding:10px;
      height: 100%;
   }
   .tab-img {
      border-radius: $border-radius-lg;
      margin-bottom:10px;
   }
   h3 {
      margin-bottom:10px;
   }
   .tab-icon {
      max-width:50px;
      max-height: 50px;
      margin-right:5px;
      @include media-breakpoint-up(lg) {
         max-width:70px;
         max-height: 70px;
      }
   }
   .tab-content {
      background:var(--white);
      padding:15px;
      @include media-breakpoint-up(lg) {
         padding:30px;
      }
   }
   .tab-text {
      padding-left:15px;
   }
}