.register {
  .register-description {
    padding-top: 40px;
    padding-bottom: 40px;
    position: relative;
    
    .wavy-line {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-content: flex-end;
      pointer-events: none;
      z-index: -1;
      flex-direction: column;
      
      svg {
        object-fit: contain;
        height: 100%;
        max-height: 450px;
      }
    }
    
    @include media-breakpoint-down(sm) {
      background: linear-gradient(90deg, var(--gradient-offset-blue) 0%, var(--primary) 100%);
    }
    @include media-breakpoint-up(sm) {
      padding-right: 40px;
      &:before {
        width: 50vw;
        position: absolute;
        background: linear-gradient(90deg, var(--gradient-offset-blue) 0%, var(--primary) 100%);
        height: 100%;
        right: 0;
        top: 0;
        max-width: 800px;
        content: "";
        z-index: -1;
      }
    }
    color: var(--white);
    
    h1,
    h2,
    h3,
    h4,
    h5,
    p,
    ul {
      margin-top: 20px;
      margin-bottom: 20px;
      color: var(--white);
    }
    
    ul {
      padding-left: 40px;
      list-style: none;
    }
    
    li {
      position: relative;
      margin-bottom: 10px;
      
      &:before {
        
        position: absolute;
        width: 22px;
        height: 17px;
        background-image: url(/images/icons/tick.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        content: "";
        top: 5px;
        left: -40px;
      }
    }
  }
  
  .register-form {
    padding-top: 40px;
    padding-bottom: 40px;
    @include media-breakpoint-down(sm) {
      background: var(--background-grey);
    }
    @include media-breakpoint-up(sm) {
      padding-left: 40px;
      &:before {
        width: 50vw;
        position: absolute;
        background: var(--background-grey);
        height: 100%;
        left: 0;
        top: 0;
        max-width: 800px;
        content: "";
        z-index: -1;
      }
    }
    
    h1,
    h2,
    h3,
    h4,
    h5,
    p {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
  
  + footer {
    margin-top: 0;
  }
}

.is-invalid {
  background-color: rgba(254, 242, 242, 1);
}
