.form-group > ul.errors{
  margin:10px 0;
 padding-left:20px;
  color:var(--red);
}
.clinical-trial {
  .clinical-trial-description {
    padding-top: 40px;
    padding-bottom: 40px;
    @include media-breakpoint-down(sm) {
      background: linear-gradient(90deg, var(--gradient-offset-blue) 0%, var(--primary) 100%);
    }
    @include media-breakpoint-up(sm) {
      padding-right: 40px;
      &:before {
        width: 50vw;
        position: absolute;
        background: linear-gradient(90deg, var(--gradient-offset-blue) 0%, var(--primary) 100%);
        height: 100%;
        right: 0;
        top: 0;
        max-width: 720px;
        content: '';
        z-index: -1;
      }
    }
    color: var(--white);

    h1,
    h2,
    h3,
    h4,
    h5,
    p,
    ul {
      margin-top: 20px;
      margin-bottom: 20px;
      color: var(--white);
    }
    ul {
      padding-left: 40px;
      list-style: none;
    }
    li{
      position: relative;
      margin-bottom:10px;
      &:before{

          position: absolute;
          width: 22px;
          height: 17px;
          background-image: url(/images/icons/tick.png);
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          content: "";
          top: 5px;
          left: -40px;
      }
    }
  }
  .clinical-trial-form {
    padding-top: 40px;
    padding-bottom: 40px;
    @include media-breakpoint-down(sm) {
      background: var(--background-grey);
    }
    @include media-breakpoint-up(sm) {
      padding-left: 40px;
      &:before {
        width: 50vw;
        position: absolute;
        background: var(--background-grey);
        height: 100%;
        left: 0;
        top: 0;
        max-width: 720px;
        content: '';
        z-index: -1;
      }
    }
    h1, 
    h2,
    h3,
    h4,
    h5,
    p {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
}
