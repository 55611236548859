.round-image-and-text {
   padding:80px 0;
   background-color: var(--background-grey);

   .rounded-img {
      border-radius: 50%;
      overflow: hidden;
   }

   .round-image-and-text-list {
      padding-left:20px;
   }
}