:root {
  --dark-text: #1d1d1b;
  --body-text: #47474d;
  --grey-text: #c4c4c4;
  --primary: #0067b1;
  --primary-transparent: #0067b166;
  --border-color: #dedcdc;
  --gradient-offset-blue: #005c9e;
  --background-grey: #f0f3f5;
  --link-blue: #0067b1;
  --white: #ffffff;
  --translucent-white: #fffd;
  --gradient-offset-green: #12862d;
  --green: #28a745;
  
}

$theme-colors: (
        "primary": #0067b1,
        "secondary": #e4ebf2,
        "success": #28a745,
);

$primary: #0067b1;
