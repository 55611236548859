.find-a-trial{
  .tags{
    li{
      // margin-right:20px;
      // border-radius:54px;
      padding:5px 15px ;
      background-color:var(--background-grey);
      margin-bottom:10px;
      border-radius: 100px;
    }
    a{
      color:var(--body-text);
    }
    li.active {
      background-color: var(--link-blue);
      a{
        color:var(--white);
      }
    }
  }
  
 
  .hospitals{
    padding-left:20px;
    @include media-breakpoint-up(md) {
      columns: 2;
    }
  }

}
.clinical-trial-card{
  border-radius:$border-radius-lg;
  border:1px var(--border-color) solid;
  margin-bottom:20px;
  padding:10px 20px;
  font-size:$small;
  @include media-breakpoint-up(lg) {
    padding:20px 30px;
  }
  strong{
    font-weight: bold;
  }
  &.hide{
    display: none;
  }
  .clinical-trial-title{
    margin-top:10px;
    margin-bottom:10px;
  }
  .clinical-trial-more{
    border:0;
    background-color:transparent;
    svg{
      transform:rotate(135deg);
    }
  }
  .clinical-trial-header{
    .status-recruiting{
      color:var(--green);
      &:before{
        display:inline-block;
        width:8px;
        height:8px;
        background-color:var(--green);
        border-radius:4px;
        content:'';
        margin-right:4px;
      }
    }
  }
}
.clinical-trials-slides{
  @include fullwidthLimit;
  margin:40px auto;
  background-color: #f0f3f5;
  .clinical-trial-card{
    background-color:#fff;
  }
  .clinical-trial-card{
    padding-bottom:0;
    margin-bottom:0;
  }

  .slide-right, .slide-left{
    position: absolute;
    top: 50%;
    z-index: 1000;
    transform: translatey(-50%);
    img{
      width:40px;
      height:18px;
      }
  }
  .slide-left{
    left: -20%;
    img{
      transform:scaleX(-1)
    }
  }
  .slide-right{
      right: -20%;
    
  }
  .inner-container{
    padding:80px 0;
  }
}