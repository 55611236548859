.brand-values {
   padding:80px 0 90px 0; 
   background-color: var(--background-grey);
   max-width:1600px;
   margin:0 auto;
   .brand-value-column {
      
      text-align: center;
      position:relative;
      &:last-of-type .dashed-line-container:after{
         display: none;
      }
      &:hover {
         cursor: pointer;
         .text-block {
            h3 {
               opacity: 0.1;
            }
            p {
               opacity: 1;
               transform: translate(-50%,-50%);
            }
         }
      }
      .text-block {
         padding:20px;
         width:100%;
         position: absolute;
         top:50%;
         left:50%;
         transform: translate(-50%,-50%);
         @include media-breakpoint-up(md) {
            padding:40px;
         }
         h3:before {
            content: "";
            width:50px;
            height: 50px;
            position: absolute;
            bottom:80%;
            left:50%;
            transform:translate(-50%,0);
            transition:0.3s ease-in-out;
            opacity: 1;
            background-size:contain;
            background-position:center;
            background-repeat: no-repeat;
         }

         h3 {
            &.challenge:before {
               background-image:url('/images/icons/challenge.svg');
            }
            &.create:before {
               background-image:url('/images/icons/lightbulb.svg');
            }
            &.transform:before {
               background-image:url('/images/icons/transform.svg');
            }
         }

         p {
            position: absolute;
            top:50%;
            left:50%;
            transform: translate(-50%,-40%);
            width:calc(100% - 60px);
            padding:30px;
            opacity: 0;
            transition:0.3s ease-in-out;
         }
      }
      .animation-container {
         &:after {
            content: "";
            display: block;
            padding-bottom: 100%;
          }
      }
      .dashed-line-container {
         width:calc(100% + 40px);
         position:absolute;
         top:50%;
         left:50%;
         transform:translate(-50%,-50%);
         z-index:0;
         .dashed-line {
            width:100%;
            animation: spin 150s infinite linear;
         }
      }

      .circle-1-1-container {
         width:calc(100% + 40px);
         position:absolute;
         top:50%;
         left:50%;
         transform:translate(-50%,-50%);
         .circle-1-1 {
            width:90%;
            animation: spin 10s infinite linear;
         }
      }

      .circle-1-2-container {
         width:calc(100% + 80px);
         position:absolute;
         top:50%;
         left:50%;
         transform:translate(-50%,-50%);
         .circle-1-2 {
            width:100%;
            animation: spin 40s infinite linear;
         }
      }

      .circle-2-1-container {
         width:calc(100% + 40px);
         position:absolute;
         top:50%;
         left:50%;
         transform:translate(-50%,-50%);
         .circle-2-1 {
            width:90%;
            animation: spin 15s infinite linear;
         }
      }

      .circle-2-2-container {
         width:calc(100% + 80px);
         position:absolute;
         top:50%;
         left:50%;
         transform:translate(-50%,-50%);
         .circle-2-2 {
            width:100%;
            animation: spin 40s infinite linear;
         }
      }

      .circle-2-3-container {
         width:calc(100% + 100px);
         position:absolute;
         top:50%;
         left:50%;
         transform:translate(-50%,-50%);
         .circle-2-3 {
            width:100%;
            animation: spin 50s infinite linear;
         }
      }

      .circle-3-1-container {
         width:calc(100% + 40px);
         position:absolute;
         top:50%;
         left:50%;
         transform:translate(-50%,-50%);
         .circle-3-1 {
            width:90%;
            animation: spin 10s infinite linear;
         }
      }

      .circle-3-2-container {
         width:calc(100% + 80px);
         position:absolute;
         top:50%;
         left:50%;
         transform:translate(-50%,-50%);
         .circle-3-2 {
            width:100%;
            animation: spin 40s infinite linear;
         }
      }

      
   }
}

@keyframes spin {
   0% {
     transform:rotate(0);
   }
   100% {
      transform:rotate(360deg);
   }
 }