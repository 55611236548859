.row-sml {
   @include make-row();
   margin-left: -7.5px;
   margin-right: -7.5px;
}

.row-sml > div[class^="col"] {
   padding-left: 7.5px;
   padding-right: 7.5px;
}

.container-fluid{
   max-width:1600px;
}