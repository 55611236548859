.clinical-trial-map{
    background-color:var(--background-grey);
    padding:80px 0;
    margin:40px 0;
    @include media-breakpoint-up(md){
        max-width: 1600px;
        margin:40px auto;
    }
    .map{
        
        img{
            max-width: 100%;
            margin:15px 0;
            @include media-breakpoint-up(lg) {
                margin:30px 0 0 0;
            }
        }
    }

    .location-list {
        margin-top:20px;
        .btn{
            margin-bottom:15px;
        }
    }

    .clinical-trial-map-title {
        margin-bottom:10px;
    }
}