.memberships {
   padding:40px 0;
   .row {
      margin-bottom: 30px;;
      &:last-of-type {
         margin-bottom:0;
      }
   }
   .membership-list {
      @include media-breakpoint-up(lg) {
         padding-left:30px;
         border-left:2px solid var(--border-color)
      }
   }
   .memberships-title {
      margin-bottom:20px;
   }

   .sml-img {
      max-width:120px;
      @include media-breakpoint-up(md) {
         position: relative;
         left:50%;
         transform:translateX(-50%);
      }
   }
}