.two-one-split {
    @include fullwidthLimit;
    margin-bottom: 6.25rem;
    background-color: var(--background-grey);
    .row {
        align-items: center;
    }
    .section-heading {
      text-align: center;
      @include gothic;
      color: var(--primary);
      font-size: 31px;
      line-height: 38px;
      margin-bottom: 60px;
    }
    .block-heading {
        color: var(--primary);
        font-size: $h3;
        margin-bottom: 15px;
    }
    .block-subheading {
        margin-bottom: 1.5rem;
        font-size: $h5;
        line-height: 1.75rem;
        font-weight: 300;
        color: var(--body-text);
    }
    .block-image {
        img {
            height: 100%;
            width: 100%;
        }
    }
    .page-home & {
        .row{
            align-items: flex-start;
        }
    }
}
