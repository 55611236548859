.green{
    color:var(--green);
}
.content-blocks{
    padding:40px 0;
    .hp-content-block{
        display: block;
        position: relative;
        z-index: 1000;
        border-radius: $border-radius-lg;
        aspect-ratio: 3/4;
        $pt:(4/3) * 100;
        overflow: hidden;
        @supports not (aspect-ratio : 3 / 4){
            &:before{
                float: left;
                padding-top: #{$pt + '%'};
                content: '';
            }
            &:after{
                display: block;
                content: '';
                clear: both;
            }
        }
        h4,h5{
            padding-left:15px;
            padding-right:15px;
            &:first-child{
                padding-top:15px;
            }
            background-color:var(--translucent-white);
            z-index: 1000;
            position: relative;
        }
        h4{
            padding-bottom:10px;
            &:after{
                content:'>';
            }
        }
        .btn {
            position:absolute;
            bottom:0;
            left:0;
            width:100%;
            z-index: 1000;
            border-top-left-radius:0;
            border-top-right-radius:0;
        }
    }
    img,svg{
        max-width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top:0;
        left:0;
        z-index: 999;
        border-radius: 20px;

    }
}