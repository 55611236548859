.thumbnail-and-text {
    padding:40px 0;
    h1 {
        margin:0 0 40px 0;
    }
    .thumbnail-and-text-blocks{
        img{
            border-radius:20px;
            margin-bottom:15px;
        }
        h3 {
            margin-bottom:15px;
        }
    }
}