.slide-left{
    left: -20%;
    img{
      transform:scaleX(-1)
    }
  }
  .slide-right{
      right: -20%;
}
.testimonials{
    h4,.h4{
        margin-bottom:10px;
    }
    .glide-3{
        position: relative;
        margin-top:60px;
    }
    .slide-left,.slide-right{
        top:100px;
        position: absolute;
        z-index: 1000;
    }
    .slide-left{
        left:0;
    }
    .slide-right{
        right:0;
    }
    .glide-3{
        .quote,h3,h4{
            margin-top:10px;
            opacity:0;
            pointer-events: none;
            transition:opacity ease-in-out 0.25s;
        

        }
    }
    .slide-content{
        transform:scale(0.8);
        transition:transform ease-in-out 0.25s;
    }
    .glide__slide--active {
        .slide-content{
            transform:scale(1);
        }
        .quote, h3,h4{
            opacity:1;
            pointer-events: initial;
        }.quote{
            width: 200%;
            transform: translateX(-25%);
        }
    }
    
}