.banner-thin {
  margin-top: 2rem;
  margin-bottom: 2rem;
  
  .banner-image {
    max-width: 90%;
    
    @include media-breakpoint-up(md) {
      max-width: 100%;
      position: absolute;
      bottom: 0;
    }
    
    &.banner-image-mask {
      max-width: 70%;
      
      @include media-breakpoint-up(md) {
        opacity: 0.3;
        max-height: 100%;
        right: 0;
        mask-image: url(/images/banner-mask.svg);
        mask-repeat: no-repeat;
        mask-size: cover;
      }
      @include media-breakpoint-up(lg) {
        opacity: 1;
      }
      
    }
    
    &.banner-image-cutout {
      max-width: 70%;
      
      @include media-breakpoint-up(md) {
        opacity: 0.3;
        max-height: 100%;
        right: 0;
      }
      @include media-breakpoint-up(lg) {
        opacity: 1;
      }
      
      @include media-breakpoint-up(xl) {
        max-height: 90%;
        right: 2.5%;
        left: auto;
        transform: translateX(0);
      }
    }
  }
  
  .container-fluid {
    &.shadow-bg {
      position: relative;
      overflow: hidden;
      
      &:before {
        @include media-breakpoint-down(md) {
          display: none;
        }
        background-image: url(/images/shadow.svg);
        background-repeat: no-repeat;
        background-position: right;
        width: 100%;
        height: 100%;
        pointer-events: none;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(0deg, transparent, white, transparent);
        mask-image: url(/images/banner-mask.svg);
        transform: scaleX(1.1);
        mask-repeat: no-repeat;
        transform: scaleX(1.03);
        mask-position: calc(100% - 20px);
      }
    }
    
    //background-color: var(--green);
    position: relative;
    
    .wavy-line {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      pointer-events: none;
      
      svg {
        object-fit: contain;
        height: 100%;
      }
      
      .wavy-line-register-sto {
        stroke: white;
        opacity: 0.2;
      }
    }
  }
  
  .banner-content {
    padding: 15px;
  }
  
  .banner-constrain {
    color: var(--white);
    @include media-breakpoint-up(md) {
      max-width: 1140px;
      max-height: 424px;
      width: 100%;
      margin: 0 auto;
      padding: 15px;
    }
    
    h2, h5 {
      @include gothic;
      color: var(--white);
    }
    
    h2 {
      padding-bottom: 15px;
    }
    
    h5 {
      font-weight: 700;
      padding-bottom: 5px;
    }
    
    .btn-primary {
      color: var(--primary);
      background-color: var(--white);
      //border: 1px solid transparent;
      //&:hover {
      //  color: var(--white);
      //  background-color: transparent;
      //  border: 1px solid var(--white);
      //}
    }
  }
}

.banner-thin--blue {
  .container-fluid {
    background: linear-gradient(90deg, var(--gradient-offset-blue) 0%, var(--primary) 100%);
  }
}

.banner-thin--green {
  .container-fluid {
    background: linear-gradient(90deg, var(--gradient-offset-green) 0%, var(--green) 100%);
  }
}
