$display-1: 6rem;
$display-2: 4.76rem;
$display-3: 3.81rem;
$display-4: 3.05rem;
$h1: 2.44rem;
$h2: 1.95rem;
$h3: 1.56rem;
$h4: 1.25rem;
$h5: 1rem;
$h6: 0.8rem;
$lead: 1.25rem;
$small: 0.9rem;


$hamburger-hover-opacity: 1 !default;
$hamburger-layer-spacing: 5px !default;
$hamburger-layer-width: 28px !default;
$hamburger-layer-height: 3px !default;
$hamburger-layer-border-radius: 0px !default;
$hamburger-layer-color: $body-color !default;


$border-radius-lg: 20px;
$border-radius-sm: 8px;
$border-radius-xs: 4px;


.bg-blue {
  background: var(--primary);
}

.bg-green {
  background: var(--green);
}

.text-blue {
  color: var(--primary);
}

.text-green {
  color: var(--green);
}
