.badge {
   border-radius: $border-radius-xs;
   &.badge-light {
      background-color: var(--border-color);
   }
   &.badge-secondary {
      background-color: var( --background-grey);
   }
}

.btn {
   .badge {
      float: right;
      margin-top:3px;
   }
}