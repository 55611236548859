.benefits {
   padding:40px 0;
   .quote-box {
      background:var(--background-grey);
      border-radius: $border-radius-lg;
      padding:20px;
      text-align: center;
      @include media-breakpoint-up(lg) {
         padding:30px 20px;
      }
      h3 {
         color:var(--green);
      }
   }
}