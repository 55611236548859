.knowledge-hub {
  margin-bottom: 60px;

  .knowledge-hub-item {
    margin-bottom: 40px;

    &.hide {
      display: none;
    }
  }

  .categories {
    margin-bottom: 30px;

    a {
      cursor: pointer;
    }
  }

  .entry-type {
    text-transform: capitalize;
  }

  .knowledge-hub-icon {
    background-color: var(--background-grey);
    padding: 10px;
    text-align: center;
    padding: 30px 10px;

    img {
      max-width: 40px;
    }
  }
}


.knowledgeHubContainer figure {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  margin-top: 50px;
}

.knowledgeHubContainer figure iframe,
.video-container figure object,
.video-container figure embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.knowledgeHubContainer ul {
  margin-left: 2rem;
}

.knowledgeHubContainer li {
  margin-bottom: 0.5rem;
}
