.homepage-banner {
    background: linear-gradient(90deg, #005c9e 0%, var(--primary) 100%);
    @include fullwidthLimit;
    .row {
        align-items: center;
    }
    .hero-brand {
        @include gothic;
        color: var(--white);
        font-size: 18px;
        line-height: 22.5px;
    }
    .hero-heading {
        margin-bottom: 1.5rem;
        color: var(--white);
        font-size: 58px;
        line-height: 72px;
        margin-bottom: 22px;
    }
    .hero-subheading {
        margin-bottom: 1.5rem;
        font-size: 1.25rem;
        line-height: 1.75rem;
        font-weight: 300;
        color: var(--white);
    }
    .hero-discover {
        display: flex;
        align-items: center;
        color: var(--white);
        transition-property: opacity;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;
        font-size: 16px;
        line-height: 24px;
        &:hover {
            color: var(--white);
            text-decoration: none;
            opacity: 0.6;
        }
        span {
            margin-left: 0.75rem;
        }
    }
    .hero-image {
        img {
            height: 100%;
            width: 100%;
        }
    }
}
