.team {
  padding: 80px 0;

  .team-item {
    margin-bottom: 30px;
  }

  .team-img {
    position: relative;
    margin-bottom: 5px;

    .team-img-img {
      border-radius: $border-radius-lg;
    }
  }

  .social-links {
    position: absolute;
    bottom: 15px;
    left: 0;
    right: 0;
    text-align: center;

    ul {
      margin: 0;
    }
  }

  .social-icon {
    width: 40px;
    height: 40px;
    line-height: 40px;
    background: var(--white);
    border-radius: 100%;
    display: block;
    box-shadow: 10px 10px 30px rgba($secondary, 0.1);

    img {
      width: 20px;
    }
  }
}
